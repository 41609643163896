<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Crear usuario
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="createUser">
        <b-form
          class="mt-2 auth-create-form"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <ImageDropzone
                ref="images"
                :files="files"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="'Nombre*'"
                label-for="account-name"
              >
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="'Nombre'"
                />
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Apellidos'"
                label-for="account-surname"
              >
                <b-form-input
                  v-model="surname"
                  :placeholder="'Apellidos'"
                  name="surname"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Email'"
                rules="required|email|emailUnique"
              >
                <b-form-group
                  :label="'Email*'"
                  label-for="account-e-mail"
                >
                  <b-form-input
                    v-model="email"
                    name="email"
                    :placeholder="'Email'"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Teléfono'"
                label-for="account-phone"
              >
                <b-form-input
                  v-model="phone"
                  name="phone"
                  :placeholder="'Teléfono'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Puesto de trabajo'"
                label-for="account-job"
              >
                <b-form-input
                  v-model="job"
                  name="job"
                  :placeholder="'Puesto de trabajo'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Rol'"
                rules="required"
              >
                <b-form-group
                  :label="'Rol*'"
                  label-for="account-rols"
                >
                  <v-select
                    v-model="roles"
                    label="name_translation"
                    :filterable="true"
                    :searchable="true"
                    :options="rolesList"
                    placeholder="Selecciona un rol"
                  />

                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div v-if="(roles.name !== 'super_admin' || roles.name !== 'tec') && $route.name === 'createUser'">
                <validation-provider
                  #default="{ errors }"
                  :name="'Cliente'"
                  :rules="
                    roles &&
                      (roles.name !== 'super_admin' || roles.name !== 'tec')
                      ? 'required'
                      : ''
                  "
                >
                  <b-form-group
                    :label="'Clientes'"
                    label-for="account-clients"
                  >
                    <v-select
                      v-model="clients"
                      label="name"
                      :filterable="true"
                      :searchable="true"
                      :options="selectClients"
                      :disabled="disabledClients"
                      :placeholder="'Cliente'"
                    />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Contraseña'"
                rules="required|min:8"
              >
                <b-form-group
                  :label="'Contraseña*'"
                  label-for="account-password"
                >
                  <b-input-group>
                    <template #append>

                      <b-input-group-text>
                        <div
                          class="append-text"
                          @click="show1 = !show1"
                        >
                          <span>
                            <feather-icon
                              :icon="show1 ? 'EyeIcon' : 'EyeOffIcon'"
                              :svg-classes="['text-white', 'h-5 w-5 mt-2']"
                            />
                          </span>
                        </div>

                      </b-input-group-text>

                    </template>
                    <b-form-input
                      v-model="password"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      placeholder="Contraseña"
                      hint="At least 8 characters"
                      counter
                      outlined
                      class="w-full"
                    />
                  </b-input-group>

                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                v-if="roles.name === 'super_admin' || roles.name === 'tec'"
                v-model="notifications"
                class="d-inline-block mb-2 mt-1"
              >
                Recibir notificaciones
              </b-form-checkbox>
            </b-col>

            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BInputGroupText,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      clients: [],
      roles: '',
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      disabledClients: true,
      surname: '',
      name: '',
      phone: '',
      avatar: '',
      show1: false,
      password: '',
      job: '',
      notifications: false,
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      departmentsSelect: 'departments/getSelectDepartments',
      selectClients: 'clients/getSelectClients',
      currentUser: 'auth/getUser',
      currentLanguage: 'languages/getCurrentLanguage'
    }),
    rolesList () {
      return this.selectRoles.map((role) => {
        if (role.display_name) {
          role.name_translation = role.display_name[this.currentLanguage];
        }
        return role
      })
    }
  },
  watch: {
    roles() {
      const rolSeleccionado = this.roles.name

      if (rolSeleccionado) {
        this.disabledClients = true
      }

      if (rolSeleccionado === 'super_admin' || rolSeleccionado === 'tec') {
        this.disabledClients = true
      } else {
        this.disabledClients = false
      }
    },

  },
  methods: {
    ...mapActions({
      create: 'users/createnew',
      selectDepartments: 'departments/selectDepartments',
      getRoles: 'users/getRoles',
      searchClients: 'clients/selectClients',

    }),
    ...mapMutations({
      setSelectDepartments: 'departments/setSelectDepartments',
    }),
    handleSubmit() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ user: formData, route: this.$route.name })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      })
    },
    clearAdmins() {
      this.selectRoles.forEach((value, index) => {
        // quitar estos roles
        if (value.name === 'super_admin' || value.name === 'tec') {
          this.selectRoles.splice(index, 1)
        }
      })
    },
    clearUsers() {
      for (let i = 0; i < this.selectRoles.length; i++) {
        if (this.selectRoles[i].name === 'admin' || this.selectRoles[i].name === 'user' || this.selectRoles[i].name === 'documental' || this.selectRoles[i].name === 'tec_client') {
          this.selectRoles.splice(i, 1)
          i--
        }
      }
    },
    async chargeData() {
      this.$forceUpdate()

      if (this.$route.name === 'createUser') {
        this.clearAdmins()
      } else {
        this.clearUsers()
      }
    },
    createFormData() {
      const data = this.$refs.images.getFormData()
      if (this.roles) {
        data.append('role', this.roles.name)
        if (this.roles.name != 'super_admin' && this.roles.name != 'tec') {
          data.append('notifications', false)
          data.append('client_id', this.clients.id)
        } else {
          data.append('notifications', this.notifications)
        }
      }
      data.append('name', this.name)
      data.append('email', this.email)
      data.append('phone', this.phone)
      data.append('surname', this.surname)
      data.append('password', this.password)
      data.append('job', this.job)

      // return false;

      return data
    },
  },
  async created() {
    // await this.selectDepartments()
    await this.getRoles()
    await this.searchClients()

    await this.chargeData()

    console.log(this.selectRoles)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
