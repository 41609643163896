var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Crear usuario ")])]),_c('b-card',[_c('validation-observer',{ref:"createUser"},[_c('b-form',{staticClass:"mt-2 auth-create-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Nombre*',"label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Apellidos',"label-for":"account-surname"}},[_c('b-form-input',{attrs:{"placeholder":'Apellidos',"name":"surname"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Email',"rules":"required|email|emailUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email*',"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"name":"email","placeholder":'Email'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Teléfono',"label-for":"account-phone"}},[_c('b-form-input',{attrs:{"name":"phone","placeholder":'Teléfono'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Puesto de trabajo',"label-for":"account-job"}},[_c('b-form-input',{attrs:{"name":"job","placeholder":'Puesto de trabajo'},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Rol',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Rol*',"label-for":"account-rols"}},[_c('v-select',{attrs:{"label":"name_translation","filterable":true,"searchable":true,"options":_vm.rolesList,"placeholder":"Selecciona un rol"},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),((_vm.roles.name !== 'super_admin' || _vm.roles.name !== 'tec') && _vm.$route.name === 'createUser')?_c('div',[_c('validation-provider',{attrs:{"name":'Cliente',"rules":_vm.roles &&
                    (_vm.roles.name !== 'super_admin' || _vm.roles.name !== 'tec')
                    ? 'required'
                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Clientes',"label-for":"account-clients"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectClients,"disabled":_vm.disabledClients,"placeholder":'Cliente'},model:{value:(_vm.clients),callback:function ($$v) {_vm.clients=$$v},expression:"clients"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1771054061)})],1):_vm._e()],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Contraseña',"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Contraseña*',"label-for":"account-password"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('div',{staticClass:"append-text",on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_c('span',[_c('feather-icon',{attrs:{"icon":_vm.show1 ? 'EyeIcon' : 'EyeOffIcon',"svg-classes":['text-white', 'h-5 w-5 mt-2']}})],1)])])]},proxy:true}],null,true)},[_c('b-form-input',{staticClass:"w-full",attrs:{"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","placeholder":"Contraseña","hint":"At least 8 characters","counter":"","outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[(_vm.roles.name === 'super_admin' || _vm.roles.name === 'tec')?_c('b-form-checkbox',{staticClass:"d-inline-block mb-2 mt-1",model:{value:(_vm.notifications),callback:function ($$v) {_vm.notifications=$$v},expression:"notifications"}},[_vm._v(" Recibir notificaciones ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Enviar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }